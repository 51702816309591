import classNames from "classnames";
import { UrlUtils } from "d-react-components";
import { map, toUpper } from "lodash";
import React from "react";
import Image from "../components/Image";
import { LANGUAGE_DEFAULT, SOCIAL_LINKS } from "../constant/app";
import OrderContact from "./order/OrderContact";
import OrderGeneralInfo from "./order/OrderGeneralInfo";
import OrderPayment from "./order/OrderPayment";
import OrderProductsDesktop from "./order/OrderProductsDesktop";
import OrderProductsMobile from "./order/OrderProductsMobile";
import OrderShippingAddress from "./order/OrderShippingAddress";

interface ILanguageItem {
    lang: string;
}

const LanguageItem = ({ lang }: ILanguageItem) => {
    const { locale = LANGUAGE_DEFAULT } = UrlUtils.getQuery();
    const classNameLanguage = classNames("mx-1 text-white cursor-pointer");

    const onChangeLanguage = () => {
        UrlUtils.addQuery({ locale: lang });
    };

    return (
        <div
            className={classNames(classNameLanguage, {
                "app-header__language-selecting": locale === lang,
            })}
            onClick={onChangeLanguage}
        >
            {toUpper(lang)}
        </div>
    );
};

const FooterDesktop = () => (
    <div className="w-100 bg-primary py-3 display-desktop-layout">
        <div className="container flex-center justify-content-between">
            <small className="text-white">
                ©2022 by De Hygienique Thailand. All rights reserved.
            </small>
            <div className="d-flex">
                {map(SOCIAL_LINKS, (social) => (
                    <a href={social.url}>
                        <Image
                            src={social.icon}
                            className="image-square-xx-small ml-2"
                        />
                    </a>
                ))}
            </div>
        </div>
    </div>
);
const FooterMobile = () => (
    <div className="w-100 bg-primary py-3 display-mobile-layout">
        <div className="flex-column flex-center justify-content-between">
            <small className="text-white">
                ©2022 by De Hygienique Thailand. All rights reserved.
            </small>
            <div className="d-flex mt-3">
                {map(SOCIAL_LINKS, (social) => (
                    <a href={social.url}>
                        <Image
                            src={social.icon}
                            className="image-square-xx-small ml-2"
                        />
                    </a>
                ))}
            </div>
        </div>
    </div>
);

const App = () => {
    return (
        <div className="w-100 h-100 flex-column">
            <div className="w-100 bg-primary">
                <div className="container flex-center position-relative app-header">
                    <Image
                        src="/images/logo-white.png"
                        className="image-reg-medium"
                    />

                    <div className="app-header__language">
                        <LanguageItem lang="en" />|
                        <LanguageItem lang="th" />
                    </div>
                </div>
            </div>
            <div className="app-content">
                <div className="container ">
                    <OrderGeneralInfo />
                    <OrderProductsDesktop />
                    <OrderProductsMobile />
                    <OrderContact />
                    <OrderShippingAddress />
                    <OrderPayment />
                </div>
                <FooterMobile />
            </div>
            <FooterDesktop />
        </div>
    );
};

export default App;
