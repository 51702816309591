import { Skeleton } from "antd";
import { Progress, UrlUtils } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import OrderAPI from "./api/order/OrderAPI";
import { AppStateContext, appStateDefault, IAppState } from "./context/app";
import { IOrder } from "./interface/order";
import App from "./screen/App";
import InitComponent from "./screen/InitComponent";

function Root() {
    const [orderDetail, setOrderDetail] = useState<IOrder>();
    const [metaData, setMetaData] = useState<IAppState>(appStateDefault);

    useEffect(() => {
        loadMetaData();
        loadOrderDetail();
    }, []);

    const loadMetaData = () => {
        OrderAPI.loadMetaData().then(setMetaData);
    };

    const loadOrderDetail = () => {
        const { id } = UrlUtils.getQuery();
        if (isEmpty(id)) return;

        Progress.show(
            { method: OrderAPI.detail, params: [id] },
            setOrderDetail as any
        );
    };

    return (
        <AppStateContext.Provider
            value={
                {
                    ...metaData,
                    orderDetail,
                    setOrderDetail,
                } as any
            }
        >
            <InitComponent />
            {orderDetail && <App />}
            {!orderDetail && <Skeleton active />}
        </AppStateContext.Provider>
    );
}

export default Root;
