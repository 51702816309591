export const DEFAULT = "DEFAULT";
export const BANK_TRANSFER = "bank-transfer";
export const C2P = "2C2P";
export const CASH = "cash";

export const PAYMENT_STATUS = {
    PENDING: "PENDING_PAYMENT",
    PENDING_CONFIRM: "PENDING_CONFIRMATION",
    REJECTED: "REJECTED",
    CONFIRMED: "CONFIRMED",
};

export const PAYMENT_STATUSES = [
    {
        id: PAYMENT_STATUS.PENDING,
        label: "paymentPending",
        color: "#FAC256",
    },
    {
        id: PAYMENT_STATUS.PENDING_CONFIRM,
        label: "paymentPendingConfirm",
        color: "#84C4FF",
    },
    {
        id: PAYMENT_STATUS.REJECTED,
        label: "rejected",
        color: "#FF1100",
    },
    {
        id: PAYMENT_STATUS.CONFIRMED,
        label: "confirmed",
        color: "#33B950",
    },
];
