import { Tooltip } from "antd";
import classNames from "classnames";
import {
    Button,
    Checkbox,
    DateInput,
    Icon,
    Notifications,
    Progress,
    StringUtils,
    TabBar,
    TimeUtils,
    UrlUtils,
    useFirstTime,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, findIndex, map, reduce, split } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Carousel, { Modal as ModalImage, ModalGateway } from "react-images";
import OrderAPI from "../../api/order/OrderAPI";
import Image from "../../components/Image";
import InputFileArea from "../../components/InputFileArea";
import InputSelectForm from "../../components/InputSelectForm";
import InputTextForm from "../../components/InputTextForm";
import SectionHeader from "../../components/SectionHeader";
import { SOCIAL_LINKS } from "../../constant/app";
import { ORDER_STATUS } from "../../constant/order";
import {
    BANK_TRANSFER,
    C2P,
    CASH,
    DEFAULT,
    PAYMENT_STATUS,
} from "../../constant/payment";
import { AppStateContext, PaymentDetailContext } from "../../context/app";
import { mapOrderPaymentProofToServer } from "../../interface/order";
import { IPayment } from "../../interface/payment";
import { IPaymentMethod } from "../../interface/paymentMethod";
import Messages from "../../languages/Messages";
import { PaymentBankProofSchema } from "../../schema/order";

interface IMethodTabView {
    dataSource: any[];
    value: any;
    onChange: any;
    disabled: boolean;
    renderTabContent: () => any;
}

interface IPaymentsProceededContent {
    icon?: any;
    content: any;
}

const MethodTabView = ({
    dataSource = [],
    value,
    onChange,
    disabled,
    renderTabContent,
}: IMethodTabView) => {
    const renderMethodLabel = (method: IPaymentMethod) => (
        <div className="d-flex justify-content-start w-100 ">
            <Checkbox variant="radio" checked={method.id === value?.id} />
            <div className="ml-3">
                {(method as any)?.[Messages.getLanguage()]?.title}
            </div>
        </div>
    );

    return (
        <div>
            {map(dataSource, (tabItem, index) => {
                const isSelect = value?.id === tabItem?.id;
                const itemClass = classNames(
                    "d-tab-bar__item text-small w-100 border-bottom",
                    {
                        "d-tab-bar__item-active": isSelect,
                    }
                );
                const icon = tabItem?.iconName ?? undefined;
                const disabledButton = disabled || tabItem?.disabled;

                let buttonProps: any = {};
                if (isSelect) {
                    buttonProps = {
                        disabled: disabledButton,
                        style: {
                            borderLeft: "5px solid #587C5F ",
                            borderBottom: "1px solid #0000001a",
                            backgroundColor: "white",
                            color: "#587C5F",
                        },
                    };
                } else {
                    buttonProps = {
                        disabled: disabledButton,
                        style: {
                            backgroundColor: disabledButton
                                ? "#dadada"
                                : "#F5F5F5",
                            borderLeft: "5px solid #F5F5F5 ",
                            color: "rgb(93, 93, 93)",
                        },
                    };
                }
                return (
                    <div>
                        <Button
                            className={itemClass}
                            onClick={() => onChange && onChange(tabItem)}
                            key={index}
                            variant="trans"
                            iconName={icon}
                            {...buttonProps}
                        >
                            {renderMethodLabel(tabItem)}
                        </Button>
                        {isSelect && renderTabContent()}
                    </div>
                );
            })}
        </div>
    );
};

const BankTransferProof = ({ proof, onClickSlip }: any) => {
    const { nameOfPayer, bankTransferred, dateOfPayment, createdAt } = proof;
    const renderRowItem = (title: any, content: any, hidden = false) => (
        <div hidden={hidden}>{`${title}: ${content}`}</div>
    );
    const proofInfo = (
        <div className="flex-column">
            {renderRowItem(
                Messages.bankOfTransfer,
                (bankTransferred as any)?.[Messages.getLanguage()]?.bankName,
                !bankTransferred
            )}
            {renderRowItem(Messages.nameOfPayer, nameOfPayer)}
            {renderRowItem(
                Messages.dateOfPayment,
                TimeUtils.toDateTime(dateOfPayment)
            )}
        </div>
    );

    const getFileName = (url: string) => {
        const names = split(url, "/");
        return names[names?.length - 1];
    };

    return (
        <div className="flex-column">
            {map(proof.slip, (slip) => (
                <div className="d-flex py-3 border-bottom w-100">
                    <div
                        className="position-relative"
                        onClick={() => onClickSlip(slip)}
                    >
                        <Image src={slip} className="image-reg-small" />
                        <Tooltip title={proofInfo}>
                            <Icon name="info" className="absolute-right" />
                        </Tooltip>
                    </div>
                    <div className="flex-column ml-3">
                        <text className="font-weight-bold">
                            {getFileName(slip)}
                        </text>
                        <small className="mt-1">
                            {`${Messages.uploadedAt} ${TimeUtils.toDateTime(
                                createdAt
                            )}`}
                        </small>
                    </div>
                </div>
            ))}
        </div>
    );
};

const BankTransferProceed = ({ method }: any) => {
    const { orderDetail, setOrderDetail } = useContext(AppStateContext);
    const { payment } = useContext(PaymentDetailContext);
    const uploadRef = useRef<any>();

    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });

    const { bankAccounts } = method;
    const proofForm = useFormik<any>({
        initialValues: { method } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PaymentBankProofSchema,
        onSubmit: (values: any) => {
            const input = mapOrderPaymentProofToServer(values);
            onUploadProofPayment(input);
        },
    });
    const formValues = proofForm?.values;
    const formErrors = proofForm?.errors;

    const onUploadProofPayment = (input: any) => {
        Progress.show(
            {
                method: OrderAPI.createProofPayment,
                params: [orderDetail.id, payment?.id, input],
            },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.uploadProofPaymentSuccess);
                setOrderDetail(orderDetailRes);
                proofForm.setValues({ method });
                uploadRef.current.refresh();
            }
        );
    };

    const mapProofImage = (url: string) => ({
        source: url,
        caption: "",
    });

    const allPaymentSlip = reduce(
        payment.proofPayment,
        (slips: any, item: any) => {
            const result = map(item.slip, mapProofImage);
            return [...slips, ...result];
        },
        []
    );

    const classNameInput = "mt-3";

    const onClickSlip = (slip: string) =>
        setImageToView({
            isVisible: true,
            index: findIndex(allPaymentSlip, (item) => item.source === slip),
        });

    const renderBankSelect = (item: any) => {
        return (
            <div className="d-flex align-items-center">
                <Image
                    src={item?.image}
                    className="image-square-x-small object-contain"
                />
                <div className="ml-1">
                    {`${item[Messages.getLanguage()].bankName} // ${
                        item?.accountNo
                    }`}
                </div>
            </div>
        );
    };

    return (
        <div>
            <text>{`${Messages.paymentShouldBeMade} ${TimeUtils.toDateTime(
                orderDetail?.expiredAt
            )}. ${Messages.onceYouMadeThePayment}`}</text>
            <div className="border p-4 mt-3 row">
                {map(bankAccounts, (bank) => (
                    <div className="col-12 d-flex mt-3 col-sm-6">
                        <Image
                            src={bank?.image}
                            className="image-square-small object-contain"
                        />
                        <div className="flex-column ml-3">
                            <small>
                                {bank[Messages.getLanguage()]?.bankName}
                            </small>
                            <small>
                                {bank[Messages.getLanguage()]?.accountName}
                            </small>
                            <small>{bank?.accountNo}</small>
                        </div>
                    </div>
                ))}
            </div>
            <label className="mt-3">{Messages.receiptOfPayment}</label>
            <div className="mt-3 flex-column">
                {map(payment?.proofPayment ?? [], (proof) => (
                    <BankTransferProof
                        proof={proof}
                        onClickSlip={onClickSlip}
                    />
                ))}
                <ModalGateway>
                    {imageToView.isVisible ? (
                        <ModalImage
                            onClose={() => setImageToView({ isVisible: false })}
                        >
                            <Carousel
                                views={allPaymentSlip}
                                currentIndex={imageToView.index}
                            />
                        </ModalImage>
                    ) : null}
                </ModalGateway>
            </div>
            <InputFileArea
                onChange={(value: any) =>
                    proofForm.setFieldValue("attachment", value)
                }
                className="mt-3"
                ref={uploadRef}
            />
            <ViewTextError error={formErrors.attachment} />
            <InputTextForm
                form={proofForm}
                keyData="nameOfPayer"
                className={classNameInput}
                label={Messages.fullNameTransferee}
                required
            />
            <InputSelectForm
                form={proofForm}
                keyData="bankAccountId"
                getLabel={renderBankSelect}
                dataSource={bankAccounts}
                className={classNameInput}
                label={Messages.bankTransferTo}
                required
            />
            <DateInput
                label={Messages.dateOfTransfer}
                onChange={(val) =>
                    proofForm.setFieldValue(
                        "dateOfPayment",
                        moment(val).toISOString()
                    )
                }
                value={
                    formValues.dateOfPayment
                        ? moment(formValues.dateOfPayment)
                        : null
                }
                className={classNameInput}
                error={formErrors.dateOfPayment as any}
                required
            />
            <div className="d-flex justify-content-end mt-3">
                <Button onClick={() => proofForm.handleSubmit()}>
                    {Messages.proceed}
                </Button>
            </div>
        </div>
    );
};

const C2PProceed = () => {
    const { orderDetail } = useContext(AppStateContext);
    const { payment } = useContext(PaymentDetailContext);
    const onProceed = () => {
        Progress.show(
            {
                method: OrderAPI.payment2C2P,
                params: [orderDetail?.id, payment?.id],
            },
            (res: any) => {
                const paymentUrl = res?.data?.paymentToken?.webPaymentUrl;
                window.open(paymentUrl, "_self");
            }
        );
    };

    return (
        <div className="p-4">
            <div>{Messages.paySecurelyByUsing2C2P}</div>
            <Image
                src="/images/payment/2c2p.png"
                className="image-reg-x-large w-100"
            />
            <div className="d-flex justify-content-end">
                <Button onClick={onProceed} className="mt-3">
                    {Messages.proceed}
                </Button>
            </div>
        </div>
    );
};

const PaymentDetail = () => {
    const { paymentMethods } = useContext(AppStateContext);
    const { payment } = useContext(PaymentDetailContext);
    const methodDefault = payment?.paymentMethod ?? paymentMethods?.[0];
    const [methodSelected, setMethodSelected] =
        useState<IPaymentMethod>(methodDefault);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        setMethodSelected(methodDefault);
    }, [payment?.id]);

    const methodTabs = filter(
        map(paymentMethods, (item) => ({
            ...item,
            disabled: item.type === CASH,
        })),
        (item) => item.type !== "DEFAULT"
    );

    const renderInfo = (title: string, content: any) => (
        <div className="d-flex mt-1">
            <text className="font-weight-bold">{`${title}: `}</text>
            <text>{content}</text>
        </div>
    );

    const renderPaymentProceed = () => {
        switch (methodSelected?.type) {
            case BANK_TRANSFER:
                return <BankTransferProceed method={methodSelected} />;
            case C2P:
                return <C2PProceed />;
            default:
                return <div />;
        }
    };
    if (payment?.status === PAYMENT_STATUS.CONFIRMED) {
        return (
            <PaymentsProceededContent
                content={Messages.yourOrderPartiallyPaid}
            />
        );
    }

    return (
        <div className="flex-column mt-3">
            {renderInfo(
                Messages.amount,
                StringUtils.moneyThaiFormat(payment.amount)
            )}
            {renderInfo(Messages.paymentRef, payment?.paymentRef)}
            <div className="font-weight-bold mt-1">
                {Messages.paymentMethod}
            </div>
            <div className="row mt-3">
                <div className="col-12 col-sm-4">
                    <MethodTabView
                        dataSource={methodTabs}
                        value={methodSelected}
                        onChange={setMethodSelected}
                        disabled={payment?.paymentMethod?.type !== DEFAULT}
                        renderTabContent={() => (
                            <div className="display-mobile-layout p-3 border-left border-right">
                                {renderPaymentProceed()}
                            </div>
                        )}
                    />
                </div>
                <div className="col-12 col-sm-8 display-desktop-layout">
                    {renderPaymentProceed()}
                </div>
            </div>
        </div>
    );
};

const PaymentsProceeding = () => {
    const { paymentId } = UrlUtils.getQuery();

    const { orderDetail } = useContext(AppStateContext);
    const { splitPayment } = orderDetail;
    const defaultPayment =
        find(splitPayment, (item) => item.id === paymentId) ??
        splitPayment?.[0];

    const [paymentSelected, setPaymentSelected] =
        useState<IPayment>(defaultPayment);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        setPaymentSelected(splitPayment?.[0]);
    }, [splitPayment]);

    return (
        <div className="card-container mt-3 p-4">
            <SectionHeader label={Messages.payment} hiddenEdit />
            <TabBar
                className="mt-3"
                dataSource={map(splitPayment, (item, index) => ({
                    ...item,
                    label: `${Messages.payment} ${index + 1}`,
                }))}
                onChange={setPaymentSelected as any}
                value={paymentSelected}
            />
            <PaymentDetailContext.Provider value={{ payment: paymentSelected }}>
                <PaymentDetail />
            </PaymentDetailContext.Provider>
        </div>
    );
};

const PaymentsProceededContent = ({
    icon = (
        <Icon
            name="check_circle"
            className="text-success my-4"
            size="xxx-large"
        />
    ),
    content,
}: IPaymentsProceededContent) => (
    <div className="flex-center flex-column mt-3">
        {icon}
        <div className="w-75 text-center">{content}</div>
        <div className="d-flex">
            {map(SOCIAL_LINKS, (link) => (
                <a href={link.url}>
                    <Image src={link.icon} className="image-square-x-small" />
                </a>
            ))}
        </div>
    </div>
);

const PaymentsProceeded = () => {
    const { orderDetail } = useContext(AppStateContext);
    let icon;
    let description: any = "";

    switch (orderDetail.status) {
        case ORDER_STATUS.DELIVERY_PROCESSING:
            description = (
                <div className="flex-column mt-3">
                    <div>{Messages.yourOrderNowProcess}</div>
                    <div>{Messages.contactOurSupport}</div>
                </div>
            );
            icon = (
                <Image
                    src="/images/status/delivery-processing.png"
                    className="image-square-small"
                />
            );
            break;
        case ORDER_STATUS.ORDER_PROCESSING:
            description = Messages.processingMessage;
            break;
        case ORDER_STATUS.CANCELLED:
            description = Messages.cancelledMessage;
            icon = (
                <Image
                    src="/images/status/cancelled.png"
                    className="image-square-xx-small"
                />
            );
            break;
        case ORDER_STATUS.COMPLETED:
            description = Messages.completedMessage;
            break;

        default:
    }

    return (
        <div className="card-container mt-3 p-4">
            <SectionHeader label={Messages.payment} hiddenEdit />
            <PaymentsProceededContent icon={icon} content={description} />
        </div>
    );
};

const OrderPayment = () => {
    const { orderDetail } = useContext(AppStateContext);

    const isPendingPayment =
        orderDetail?.status === ORDER_STATUS.PENDING_PAYMENT ||
        orderDetail?.status === ORDER_STATUS.PENDING_CONFIRMATION ||
        orderDetail?.status === ORDER_STATUS.PARTIALLY_PAID;

    if (!isPendingPayment) {
        return <PaymentsProceeded />;
    }
    return <PaymentsProceeding />;
};

export default OrderPayment;
