export default {
    invoiceNo: "Invoice No.",
    createdAt: "Created At",
    expireAt: "Expire At",
    orderStatus: "Order Status",
    itemName: "Item name",
    unitPrice: "Unit Price",
    quantity: "Quantity",
    price: "Price",
    subTotal: "Subtotal",
    total: "Total",
    orderContact: "Order contact",
    edit: "Edit",
    orderContactDesc:
        "Please note that we will send the order confirmation as well as all other order activities to this email. You can skip it if you don't have any email.",
    confirmEmail: "Confirm email",
    phone: "Phone No.",
    confirmPhone: "Confirm phone No.",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    save: "Save",
    cancel: "Cancel",
    shippingInformation: "Shipping Information",
    nickName: "Nick Name",
    province: "Province",
    district: "District/City",
    subDistrict: "Sub-district",
    postCode: "Postcode",
    address: "Address",
    typeOfPlace: "Type of place",
    nameOfPlace: "Name of place",
    partiallyPaid: "Partially Paid",
    processing: "Processing",
    orderProcessing: "Order Processing",
    deliveryProcessing: "Delivery Processing",
    delivered: "Delivered",
    completed: "Completed",
    refunded: "Refunded",
    cancelled: "Cancelled",
    expired: "Expired",
    sku: "SKU",
    transportationFee: "Transportation fee",
    phoneNotMatch: "Confirm phone not match",
    emailNotMatch: "Confirm email not match",
    updateOrderContactSuccess: "Update order contact successfully!",
    shippingAddress: "Shipping address",
    country: "Country",
    postcode: "Postcode",
    condominium: "Condominium/Apartment",
    privateHouse: "Private House",
    companyOffice: "Company/Office",
    updateShippingAddressSuccess: "Update shipping address successfully!",
    payment: "Payment",
    amount: "Amount",
    paymentRef: "Payment Ref",
    paymentMethod: "Payment method",
    paySecurelyByUsing2C2P:
        "Pay securely by using 2C2P. Click proceed to be redirected to 2C2P system to complete the payment.",
    proceed: "Proceed",
    paymentShouldBeMade: "Payments should be made before",
    onceYouMadeThePayment:
        "Once you made the payment, please attach the receipt of the payment below",
    receiptOfPayment: "Receipt of payment (Bank Slip)",
    dragAndDrop: "Drag and drop file here (Maximum 2 files per upload)",
    or: "-- or --",
    browse: "Browse",
    dateOfTransfer: "Date of transfer",
    fullNameTransferee: "Full name transferee",
    bankTransferTo: "Bank transferred to",
    cantChooseFutureDate: "Can not choose future date",
    uploadProofPaymentSuccess: "Upload proof payment successfully!",
    uploadedAt: "Upload at",
    nameOfPayer: "Name of payer",
    bankOfTransfer: "Bank transferred to",
    transaction: "Transaction",
    dateOfPayment: "Date of payment",
    pendingConfirmation: "Pending Confirmation",
    pending: "Pending",
    processingMessage:
        "Thank you very much. Your order has been successfully paid and confirmed. Once your order is ready for delivery, we will update the tracking code via your email. Should you have any question, please contact our customer support via channels below",
    cancelledMessage:
        "Sorry! Your order has been cancelled. Should you have any question, please contact our customer support via channels below",
    deliveredMessage:
        "Your order has been successfully delivered. Should you have any question, please contact our customer support via channels below",
    completedMessage:
        "Your order has been completed. Should you have any question, please contact our customer support via channels below",
    yourOrderPartiallyPaid:
        "Thank you very much for your payment. Your order is partially paid and confirmed. Should you have any question, please contact our customer support via channels below",
    yourOrderNowProcess: "Your order is now being processed for the delivery.",
    contactOurSupport:
        "Should you have any question, please contact our customer support via channels below",
    list: "List",
    summary: "Summary",
    items: "Items",
    updateContact: "Update contact",
    updateShippingAddress: "Update shipping address",
};
