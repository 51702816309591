import { gql } from "@apollo/client";
import { map } from "lodash";
import { appStateDefault, IAppState } from "../../context/app";
import API from "../API";
import { F_ORDER, F_PAYMENT_METHOD } from "./OrderFragment";

const detailConvertor = {
    fromServer: (res: any) => res?.data?.order,
};

const metaDataConvertor = {
    fromServer: (res: any): IAppState => ({
        ...appStateDefault,
        paymentMethods: res?.data?.paymentMethods ?? [],
        provinceList: res?.data?.provinces ?? [],
        countryList: map(res?.data?.nationality ?? [], (item) => ({
            id: item,
            name: item,
        })),
    }),
};

export default {
    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_ORDER}
                query order($id: ID!) {
                    order(id: $id) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { id },
        }),

    updateContact: (orderId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateOrderContact(
                    $orderId: ID!
                    $input: OrderContactInput
                ) {
                    order: updateOrderContact(
                        orderId: $orderId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, input },
        }),

    updateServiceAddress: (orderId: string, addressId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation updateOrderShipping(
                    $orderId: ID!
                    $addressId: ID!
                    $input: ShippingInput
                ) {
                    order: updateOrderShipping(
                        orderId: $orderId
                        shippingId: $addressId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, addressId, input },
        }),

    createProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation createProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: ProofPaymentInput
                ) {
                    order: createProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),

    confirmProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation confirmProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: ConfirmProofPaymentInput
                ) {
                    order: confirmProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),
    rejectProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_ORDER}
                mutation rejectProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: RejectProofPaymentInput
                ) {
                    order: rejectProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_ORDER
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),

    loadMetaData: () =>
        API.withConverter(metaDataConvertor).query({
            query: gql`
                ${F_PAYMENT_METHOD}
                query loadMetaData {
                    provinces {
                        id
                        name
                    }

                    nationality

                    paymentMethods {
                        ...F_PAYMENT_METHOD
                    }
                }
            `,
        }),

    payment2C2P: (orderId: string, paymentId: string) =>
        API.mutate({
            mutation: gql`
                mutation payment2C2P($orderId: ID!, $paymentId: ID!) {
                    paymentToken(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                    ) {
                        webPaymentUrl
                        paymentToken
                        respCode
                        respDesc
                    }
                }
            `,
            variables: { orderId, paymentId },
        }),
};
