import { TimeUtils } from "d-react-components";
import * as Yup from "yup";
import Messages from "../languages/Messages";

export const OrderContactSchema = Yup.object().shape({
    email: Yup.string().required("Required field!"),
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    phone: Yup.string().required("Required field!"),
    confirmPhone: Yup.string().required("Required field!"),
    confirmEmail: Yup.string().required("Required field!"),
});

export const PaymentBankProofSchema = Yup.object().shape({
    nameOfPayer: Yup.string().required("Name of payer required!"),
    dateOfPayment: Yup.date()
        .max(TimeUtils.tomorrow(), Messages.cantChooseFutureDate)
        .required("Date of payment date required!"),
    attachment: Yup.array()
        .required("Attachment required!")
        .min(1, "Attachment required!"),
    bankAccountId: Yup.string().required("Bank required!"),
});
