import {
    AwesomeTableComponent,
    IColumnsProps,
    StringUtils,
} from "d-react-components";
import { find, map } from "lodash";
import React, { useContext, useRef } from "react";
import Image from "../../components/Image";
import { ORDER_FEES } from "../../constant/order";
import { AppStateContext } from "../../context/app";
import { IOrder, IOrderProduct } from "../../interface/order";
import Messages from "../../languages/Messages";
import SectionHeader from "../../components/SectionHeader";

interface IOrderProductItem {
    product: IOrderProduct;
}

const OrderProductItem = ({ product }: IOrderProductItem) => (
    <div className="d-flex align-items-center pb-3 border-bottom mt-3">
        <Image className="image-reg-small" src={product?.thumbnail} />
        <div className="flex-column ml-3 w-100">
            <div>{product?.name}</div>
            <small>{`${Messages.sku}: ${product?.SKU}`}</small>
            <div className="d-flex justify-content-between w-100">
                <small>{`${Messages.quantity}: ${product?.quantity}`}</small>
                <small>{StringUtils.moneyThaiFormat(product?.salePrice)}</small>
            </div>
        </div>
    </div>
);

const OrderProductsMobile = () => {
    const { orderDetail } = useContext(AppStateContext);
    const { products, subTotal, total, vouchers, manualCode, fees } =
        orderDetail as IOrder;

    const renderSummaryRow = (title: any, content: any) => (
        <div className="d-flex justify-content-between mt-1">
            <label>{title}</label>
            <text>{content}</text>
        </div>
    );

    const renderSummaryPriceRow = (title: any, price: number) =>
        renderSummaryRow(title, StringUtils.moneyThaiFormat(price));

    const renderSummaryVoucherRow = (title: any, price: number) => {
        return renderSummaryRow(
            title,
            <text className="text-success">
                {`-${StringUtils.moneyThaiFormat(price)}`}
            </text>
        );
    };

    const renderOrderSummary = () => (
        <div>
            <SectionHeader label={Messages.summary} hiddenEdit />
            {renderSummaryPriceRow(Messages.subTotal, subTotal)}
            {map(vouchers, (voucher: any) =>
                renderSummaryVoucherRow(
                    `Voucher (${voucher.code})`,
                    voucher?.discount
                )
            )}
            {map(manualCode, (manualDiscount: any) =>
                renderSummaryVoucherRow(
                    `Manual discount (${manualDiscount?.code})`,
                    manualDiscount?.discount
                )
            )}

            {map(fees, (fee: any) => {
                const orderFee = find(
                    ORDER_FEES,
                    (item) => item.id === fee.type
                );
                return renderSummaryPriceRow(
                    (Messages as any)[orderFee?.label as string],
                    fee?.value
                );
            })}

            {renderSummaryRow(
                Messages.total,
                <div className="text-bold">
                    {StringUtils.moneyThaiFormat(total)}
                </div>
            )}
            <div
                className="p-3 text w-100 bg-note mt-1"
                hidden={!orderDetail.isInvoice}
            >
                This customer requested the tax invoice
            </div>
        </div>
    );

    return (
        <div className="display-mobile-layout">
            <div className="card-container mt-3 p-4 flex-column">
                <SectionHeader label={Messages.items} hiddenEdit />
                {map(products, (product) => (
                    <OrderProductItem product={product} />
                ))}
            </div>
            <div className="card-container mt-3 p-4 ">
                {renderOrderSummary()}
            </div>
        </div>
    );
};

export default OrderProductsMobile;
