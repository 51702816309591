export const LANGUAGE_DEFAULT = "en";

export const LANGUAGES = [
    {
        label: "Thai",
        id: "th",
        locale: "th",
        code: "TH",
        default: true,
    },
    {
        label: "English",
        id: "en",
        locale: "en",
        code: "EN",
    },
];

export const SOCIAL_LINKS = [
    {
        id: "facebook",
        url: "http://www.facebook.com",
        icon: "/images/social/facebook.png",
    },
    {
        id: "instagram",
        url: "http://www.facebook.com",
        icon: "/images/social/instagram.png",
    },
    {
        id: "line",
        url: "http://www.facebook.com",
        icon: "/images/social/line.png",
    },
];
