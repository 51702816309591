import {
    AwesomeTableComponent,
    IColumnsProps,
    StringUtils,
} from "d-react-components";
import { find, map } from "lodash";
import React, { useContext, useRef } from "react";
import Image from "../../components/Image";
import { ORDER_FEES } from "../../constant/order";
import { AppStateContext } from "../../context/app";
import { IOrder, IOrderProduct } from "../../interface/order";
import Messages from "../../languages/Messages";

interface IOrderProductItem {
    product: IOrderProduct;
}

const OrderProductItem = ({ product }: IOrderProductItem) => (
    <div className="d-flex">
        <Image className="image-reg-small" src={product?.thumbnail} />
        <div className="flex-column ml-3">
            <div>{product?.name}</div>
            <small>{`${Messages.sku}: ${product?.SKU}`}</small>
        </div>
    </div>
);

const OrderProductsDesktop = () => {
    const { orderDetail } = useContext(AppStateContext);
    const { products, subTotal, total, vouchers, manualCode, fees } =
        orderDetail as IOrder;
    const tableRef = useRef<any>();
    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product) => <OrderProductItem product={product} />,
        },
        {
            title: Messages.unitPrice,
            dataIndex: "salePrice",
            render: StringUtils.moneyThaiFormat,
            align: "center",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            align: "center",
        },
        {
            title: Messages.price,
            dataIndex: "",
            align: "right",
            render: (product) =>
                StringUtils.moneyThaiFormat(
                    product.salePrice * product.quantity
                ),
        },
    ];

    const renderSummaryRow = (title: any, content: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center border-bottom py-3 pr-2">
                <div>{title}</div>
                <div>{content}</div>
            </div>
        );
    };

    const renderSummaryPriceRow = (title: any, price: number) => {
        return renderSummaryRow(title, StringUtils.moneyThaiFormat(price));
    };

    const renderSummaryVoucherRow = (title: any, price: number) => {
        return renderSummaryRow(
            title,
            <text className="text-success">
                {`-${StringUtils.moneyThaiFormat(price)}`}
            </text>
        );
    };

    const renderOrderSummary = () => (
        <div className=" d-flex justify-content-end">
            <div className="w-50">
                {renderSummaryPriceRow(Messages.subTotal, subTotal)}
                {map(vouchers, (voucher: any) =>
                    renderSummaryVoucherRow(
                        `Voucher (${voucher.code})`,
                        voucher?.discount
                    )
                )}
                {map(manualCode, (manualDiscount: any) =>
                    renderSummaryVoucherRow(
                        `Manual discount (${manualDiscount?.code})`,
                        manualDiscount?.discount
                    )
                )}

                {map(fees, (fee: any) => {
                    const orderFee = find(
                        ORDER_FEES,
                        (item) => item.id === fee.type
                    );
                    return renderSummaryPriceRow(
                        (Messages as any)[orderFee?.label as string],
                        fee?.value
                    );
                })}

                {renderSummaryRow(
                    <div className="text-bold">{Messages.total}</div>,
                    <div className="text-bold">
                        {StringUtils.moneyThaiFormat(total)}
                    </div>
                )}
                <div
                    className="p-3 text w-100 bg-note"
                    hidden={!orderDetail.isInvoice}
                >
                    This customer requested the tax invoice
                </div>
            </div>
        </div>
    );
    return (
        <div className="card-container mt-3 p-4 display-desktop-layout">
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                dataSource={products}
                bordered={false}
                pagination={false}
            />
            {renderOrderSummary()}
        </div>
    );
};

export default OrderProductsDesktop;
