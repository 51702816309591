import { Select } from "d-react-components";
import Messages from "../languages/Messages";

interface IInputSelectForm {
    form: any;
    keyData: string;
    label?: any;
    className?: any;
    [key: string]: any;
}

const InputSelectForm = ({
    form,
    keyData,
    label,
    className,
    ...props
}: IInputSelectForm) => {
    // eslint-disable-next-line no-unneeded-ternary
    const labelValue = label ? label : (Messages as any)[keyData];
    return (
        <Select
            className={className}
            label={labelValue}
            name={keyData}
            error={form.errors[keyData]}
            value={form.values[keyData]}
            onChange={(value) => form.setFieldValue(keyData, value)}
            placeholder={labelValue}
            getLabel={(item) => (Messages as any)[item.label]}
            {...props}
        />
    );
};

export default InputSelectForm;
