import { map } from "lodash";
import moment from "moment";
import { IBillingAddress, IServiceAddress } from "./address";
import { ICustomer } from "./customer";
import { IPayment } from "./payment";

export interface IOrderDiscount {
    discount: number;
    code: string;
}

export interface IOrderFee {
    value: number;
    type: string;
}

export interface IOrderDiscountDisplay {
    total: number;
    subTitle: string;
    title: string;
    id: string;
    [key: string]: any;
}

export interface IOrderCreateState {
    discountList: IOrderDiscountDisplay[];
    subtotal: number;
    total: number;
    tax: number;
}

export interface IOrderProduct {
    id: string;
    name: string;
    SKU: string;

    thumbnail: string;
    regularPrice: number;
    salePrice: number;
    quantity: number;
}

export interface IOrderContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface IOrderWorkSheet {
    id: string;
    workSheetNo: string;
    createdAt: string;
}

export interface IOrder {
    id: string;
    orderNo: string;
    products: IOrderProduct[];
    subTotal: number;
    coupons: string[];
    discountVoucher: number;
    discountManual: number;

    tax: number;
    total: number;
    note: string;
    customer: ICustomer;
    orderContact: IOrderContact;
    shippings: IServiceAddress[];
    billing: IBillingAddress;
    status: string;
    splitPayment: IPayment[];
    createdAt: string;
    expiredAt: string;
    vouchers: IOrderDiscount;
    manualCode: IOrderDiscount;
    fees: IOrderFee;
    isInvoice: boolean;
    workSheets: IOrderWorkSheet[];
}

export const mapOrderContactToServer = (orderContact: any) => {
    const result = { ...orderContact };
    delete result.confirmEmail;
    delete result.confirmPhone;
    return result;
};

export const mapOrderPaymentProofToServer = (body: any) => {
    if (!body) return {};
    const { attachment, dateOfPayment, method, ...rest } = body;
    return {
        ...rest,
        paymentMethodId: method?.id,
        dateOfPayment: moment(dateOfPayment).toISOString(),
        slip: map(attachment, (item) => item.url),
    };
};
