import { gql } from "@apollo/client";
import API from "../API";

interface IUploadParam {
    file?: any;
    base64?: string;
}

export default {
    uploadMedia: ({ file, base64 }: IUploadParam) =>
        API.instanceUpload.mutate({
            mutation: gql`
                mutation ($file: Upload, $base64: String) {
                    upload(file: $file, base64: $base64) {
                        url
                    }
                }
            `,
            variables: { file, base64 },
        }),
};
