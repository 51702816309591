import { gql } from "@apollo/client";

export const F_ORDER_DISCOUNT = gql`
    fragment F_ORDER_DISCOUNT on Discount {
        code
        discount
    }
`;

export const F_ADDRESS = gql`
    fragment F_ADDRESS on Address {
        id
        firstName
        lastName
        nickName
        gender
        phone
        country
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        address
        typeOfPlace
        nameOfPlace
        pin
        remark
    }
`;

export const F_PAYMENT_METHOD = gql`
    fragment F_PAYMENT_METHOD on PaymentMethod {
        id
        th {
            title
            description
        }
        en {
            title
            description
        }
        type
        priority
        status
        minimum
        maximum
        bankAccounts {
            id
            image
            th {
                bankName
                accountName
            }
            en {
                bankName
                accountName
            }
            accountNo
        }
        updatedAt
    }
`;

export const F_ORDER = gql`
    ${F_ADDRESS}
    ${F_PAYMENT_METHOD}
    ${F_ORDER_DISCOUNT}
    fragment F_ORDER on Order {
        id
        orderNo
        products {
            id
            name
            SKU
            thumbnail
            regularPrice
            salePrice
            quantity
        }
        subTotal

        manualCode {
            ...F_ORDER_DISCOUNT
        }
        # vouchers {
        #     ...F_ORDER_DISCOUNT
        # }

        # discountVoucher
        discountManual

        tax
        total
        note

        orderContact {
            firstName
            lastName
            email
            phone
        }
        shippings {
            ...F_ADDRESS
        }

        status
        isInvoice: invoice
        splitPayment {
            id
            paymentMethod {
                ...F_PAYMENT_METHOD
            }
            amount
            status
            proofPayment {
                id
                paymentMethod {
                    ...F_PAYMENT_METHOD
                }
                nameOfPayer
                bankTransferred {
                    id
                    image
                    th {
                        bankName
                        accountName
                    }
                    en {
                        bankName
                        accountName
                    }
                    accountNo
                }
                dateOfPayment
                slip
                remark
                status
                transactionId
                createdAt
            }
            confirmation: confirmationPayment
            paymentRef
        }
        createdAt
        expiredAt

        fees: transportationFee {
            type
            value
        }
    }
`;
